import "./App.css";
import { LandingPage } from "./components/views/LandingPage";

function App() {
  return (
    <div className="App">
      <LandingPage />
    </div>
  );
}

export default App;
